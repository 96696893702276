<template>
  <b-row>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="CpuIcon"
        :statistic="statistic.total_payin ? statistic.total_payin.toLocaleString() : 0"
        :statistic-title="$t('Tổng tiền payin')"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="ServerIcon"
        color="success"
        :statistic="statistic.total_payout.toLocaleString()"
        :statistic-title="$t('Tổng tiền payout')"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="ActivityIcon"
        color="danger"
        :statistic="statistic.total_bank.toLocaleString()"
        :statistic-title="$t('Tổng tiền rút về bank')"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="AlertOctagonIcon"
        color="warning"

        :statistic="statistic.total_amount.toLocaleString() || 0"
        :statistic-title="$t('Tổng tiền còn lại')"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { useMasterFilter } from '@/views/order-management/useOrder'
import { onMounted, ref } from '@vue/composition-api'

export default {
  name: 'WithdrawStatistics',
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
  },
  setup() {
    const {
      fetchWithdrawStatistic,
    } = useMasterFilter()

    const statistic = ref({
      total_payin: 0,
      total_payout: 0,
      total_bank: 0,
      total_amount: 0,
    })

    const getWithdrawStatistic = () => {
      fetchWithdrawStatistic({
        type: null,
      }).then(res => {
        statistic.value = res
      })
    }

    const reload = () => {
      getWithdrawStatistic()
    }

    onMounted(() => {
      getWithdrawStatistic()
    })

    return {
      statistic,
      reload,
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div>
      <withdraw-statistics ref="withdrawStatistics" />
      <b-row>
        <b-col cols="4">
          <validation-observer
            ref="createOrderForm"
            #default="{invalid}"
          >
            <b-form
              @submit.prevent="addMoney"
            >
              <b-card :title="$t('Rút tiền')">
                <b-form-group
                  :label="$t('Nguồn tiền')"
                  label-for="source"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Nguồn tiền')"
                    vid="source"
                    rules="required"
                  >
                    <v-select
                      id="source"
                      v-model="source"
                      :placeholder="$t('Lựa chọn nguồn tiền')"
                      label="label"
                      :options="sourceOptions"
                      :reduce="item => item.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="$t('Nhập số tiền cần rút')"
                  label-for="amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Số tiền')"
                    vid="amount"
                    rules="required|money-payment"
                  >
                    <b-form-input
                      id="amount"
                      v-model="amount"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="$t('Nơi nhận')"
                  label-for="output"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Nơi nhận')"
                    vid="output"
                    rules="required"
                  >
                    <v-select
                      id="output"
                      v-model="output"
                      :placeholder="$t('Lựa chọn nơi nhận')"
                      label="label"
                      :options="outputOptions"
                      :reduce="item => item.value"
                      :selectable="(option) => enableItemReceive(option.value)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="output === 'bank'"
                  :label="$t('Tài khoản ngân hàng')"
                  label-for="output"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Tài khoản ngân hàng')"
                    vid="output"
                    rules="required"
                  >
                    <v-select
                      id="output"
                      v-model="bankAccount"
                      :placeholder="$t('Chọn tài khoản')"
                      label="bank_name"
                      :options="bankAccountOptions"
                      :reduce="item => item.real_id"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-card-text class="d-flex justify-content-center">
                  <b-button
                    variant="outline-primary"
                    class="mr-1"
                    @click="resetData"
                  >
                    {{ $t('Làm mới') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    class="ml-1"
                    :disabled="invalid"
                    @click="addMoney"
                  >
                    {{ $t('Thực hiện') }}
                  </b-button>
                </b-card-text>
              </b-card>
            </b-form>
          </validation-observer>
        </b-col>

        <b-col cols="8">
          <b-card :title="$t('Lịch sử rút tiền')">
            <b-row class="mb-3">
              <b-col cols="6">
                <b-form-input
                  v-model="searchKeyWord"
                  :placeholder="$t('Mã giao dịch/nguồn tiền')"
                />
              </b-col>
              <b-col cols="2">
                <b-button
                  variant="primary"
                  @click="fetchWithdrawalList"
                >
                  {{ $t('Tìm kiếm') }}
                </b-button>
              </b-col>
            </b-row>
            <b-card-text>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                :busy.sync="isBusy"
                responsive
                show-empty
                bordered
              >
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #empty>
                  <b-row>
                    <b-col>
                      <b-alert
                        show
                        variant="info"
                        class="text-center"
                      >
                        {{ $t('Không có dữ liệu') }}
                      </b-alert>
                    </b-col>
                  </b-row>
                </template>
                <template #cell(bank_master.code)="data">
                  <span class="text-nowrap">{{ data.value ? data.value : data.item.to }}</span>
                </template>
                <template #cell(created_at)="data">
                  <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
                </template>
                <template #cell(updated_at)="data">
                  <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
                </template>
              </b-table>
            </b-card-text>

            <b-row>
              <b-col class="d-flex justify-content-md-end">
                <b-pagination
                  v-model="pagination.current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  :first-text="$t('Trang đầu')"
                  :prev-text="$t('Trang trước')"
                  :next-text="$t('Trang tiếp')"
                  :last-text="$t('Trang cuối')"
                  @change="changePage"
                />
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BCardText,
  BTable,
  BAlert,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, moneyPayout, integer, moneyPayment,
} from '@validations'
import Swal from 'sweetalert2'
import { formatDateTimeDb } from '@/libs/timezone'
import WithdrawStatistics from '@/views/order-management/WithdrawStatistics.vue'
// eslint-disable-next-line import/no-cycle
import { useI18n } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import { useCreateWithdrawal } from './useOrder'

export default {
  name: 'Wallet',
  components: {
    WithdrawStatistics,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCard,
    BCardText,
    BTable,
    BAlert,
    BPagination,
    BOverlay,
  },
  setup() {
    const { t } = useI18n()
    const createOrderForm = ref(null)
    const showOverlay = ref(false)
    const {
      createWithdrawal,
      fetchWithdrawal,
      fetchBankAvailable,
    } = useCreateWithdrawal()

    const withdrawStatistics = ref(null)
    const itemView = ref('pay')
    const itemViewOptions = ref([
      { icon: 'EyeIcon', value: 'pay', text: t('Rút tiền') },
      { icon: 'EyeOffIcon', value: 'balance', text: t('Số dư các ví') },
    ])
    const amount = ref('')
    const source = ref('')
    const searchKeyWord = ref('')
    const sourceOptions = ref([
      { value: 'payin', label: t('Payin') },
      { value: 'payout', label: t('Payout') },
    ])
    const output = ref('')
    const outputOptions = ref([
      { value: 'bank', label: t('tài khoản ngân hàng') },
      { value: 'payout', label: t('Payout') },
      { value: 'payin', label: t('Payin') },
    ])
    const bankAccount = ref('')
    const bankAccountOptions = ref([])

    // table
    const fields = ref([
      {
        key: 'status',
        label: t('Trạng thái'),
        thClass: 'text-center',
        tdClass: 'text-center',
        class: 'text-nowrap',
        formatter: value => t(value),
      },
      {
        key: 'id',
        label: t('Mã giao dịch'),
      },
      {
        key: 'bank_master.code',
        label: t('Tên ngân hàng'),
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'bank.bank_account',
        label: t('Tên tài khoản'),
      },
      {
        key: 'bank.bank_number',
        label: t('Số tài khoản'),
      },
      {
        key: 'amount',
        label: t('số tiền rút'),
        thClass: 'text-right',
        tdClass: 'text-right',
        formatter: value => value.toLocaleString(),
      },
      { key: 'created_at', label: t('Thời gian tạo') },
      { key: 'updated_at', label: t('Thời gian cập nhật') },
    ])
    const items = ref([])
    const isBusy = ref(false)
    const pagination = ref({
      total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
    })

    const resetData = () => {
      source.value = ''
      output.value = ''
      bankAccount.value = ''
      amount.value = ''
    }

    const fetchWithdrawalList = async () => {
      isBusy.value = true
      const response = await fetchWithdrawal({
        page: pagination.value.current_page,
        search: searchKeyWord.value,
      })
      if (response) {
        items.value = response.data
        pagination.value = response.meta
      }
      isBusy.value = false
    }

    const fetchBankAvailableList = async () => {
      const response = await fetchBankAvailable()
      if (response) {
        bankAccountOptions.value = response.data.map(item => ({
          real_id: item.real_id,
          bank_name: `${item.bank_number} | ${item.bank_account} | ${item.bank_name}`,
        }))
      }
    }

    const addMoney = () => {
      showOverlay.value = true
      createWithdrawal({
        amount: amount.value,
        from: source.value,
        to: output.value,
        bank_id: bankAccount.value,
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: t('Thành công'),
          text: t('Tạo lệnh rút tiền thành công'),
          confirmButtonText: t('Đóng'),
        })
        resetData()
        createOrderForm.value.reset()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: t('Thất bại'),
          text: t(err.response.data.message),
          confirmButtonText: t('Đóng'),
        })
      }).finally(() => {
        showOverlay.value = false
        fetchWithdrawalList()
        withdrawStatistics.value.reload()
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchWithdrawalList()
    }

    const enableItemReceive = item => {
      if (item === source.value) {
        return false
      }
      return !(item === 'bank' && source.value === 'payout')
    }

    onMounted(() => {
      fetchWithdrawalList()
      fetchBankAvailableList()
    })

    return {
      showOverlay,
      createOrderForm,
      withdrawStatistics,
      addMoney,
      itemView,
      itemViewOptions,
      source,
      sourceOptions,
      output,
      outputOptions,
      amount,
      searchKeyWord,
      bankAccount,
      bankAccountOptions,
      fetchWithdrawalList,

      // table
      fields,
      items,
      isBusy,

      // pagination
      pagination,
      changePage,

      // rules
      required,
      moneyPayout,
      moneyPayment,

      formatDateTimeDb,
      enableItemReceive,
      resetData,
      integer,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
